import React from 'react';
import { Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useTransition, animated, config } from 'react-spring';
import Plx from 'react-plx';
import AnimeImage from '../Components/AnimeImage';
import hourglass from '../images/icons/hourglass.svg';
import ortheogrid from '../images/icons/ortheo-grid.svg';

const headerPlxData = [
  {
    start: 'self',
    startOffset: '20vh',
    duration: '20vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -450,
        property: 'translateY',
      },
    ],
  },
];

const headerMobilePlxData = [
  {
    start: 'self',
    startOffset: '0vh',
    duration: '20vh',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
      {
        startValue: 90,
        endValue: 90,
        unit: '%',
        property: 'translateY',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '20vh',
    duration: '20vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 90,
        endValue: 90,
        unit: '%',
        property: 'translateY',
      },
    ],
  },
];

const paragraphTwoPlxData = [
  {
    start: '20vh',
    duration: '20vh',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
      {
        startValue: 10,
        endValue: 10,
        unit: '%',
        property: 'translateY',
      },
    ],
  },
  {
    start: '60vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
];

const paragraphThreePlxData = [
  {
    start: '80vh',
    duration: '20vh',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
      {
        startValue: -80,
        endValue: -80,
        unit: '%',
        property: 'translateY',
      },
    ],
  },
  {
    start: '100vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: -80,
        endValue: -125,
        unit: '%',
        property: 'translateY',
      },
    ],
  },
];

const paragraphList = [
  <Text textStyle='titleNormal' textAlign='center' fontSize='28px' lineHeight='38px' color='#FCFAF8' maxW='785px'>
    Seismic change is underway. Every day, thousands of people are accessing the internet for the first time in their
    lives. Low income and rural communities are adopting digital money. Yet, for many who have been traditionally left
    out, emerging digital tools are not truly adapted to their aspirations and realities.
  </Text>,
  <Text
    mt='50px'
    textStyle='titleNormal'
    textAlign='center'
    fontSize='28px'
    lineHeight='38px'
    maxWidth='785px'
    color='#FCFAF8'>
    We envision a future where women and last-mile communities can access digital financial services with a well of
    confidence, agency, and power.
  </Text>,
  <Text mt='50px' textStyle='titleNormal' fontSize='28px' lineHeight='38px' color='#FCFAF8'>
    But to build that future, we first need to imagine it.
  </Text>,
];

const mobileParagraphList = [
  <Text textStyle='titleNormal' textAlign='center' fontSize='22px' lineHeight='30px' color='#FCFAF8' mx='40px'>
    Seismic change is underway. Every day, thousands of people are accessing the internet for the first time in their
    lives. Low income and rural communities are adopting digital money.
  </Text>,
];

export default function IntroPageOne({ setToggleMsgScroll }) {
  const transitions = useTransition(paragraphList, {
    from: { opacity: 0, transform: 'scale(0.9) translate3d( 0, 0, 0) perspective(1px)' },
    enter: { opacity: 1, transform: 'scale(1.0) translate3d( 0, 0, 0) perspective(1px)' },
    delay: 500,
    config: { ...config.molasses },
    trail: 2500,
    onRest: () => setToggleMsgScroll(true),
  });

  const mobileTransitions = useTransition(mobileParagraphList, {
    from: { opacity: 0, transform: 'scale(0.9) translate3d( 0, 0, 0) perspective(1px)' },
    enter: { opacity: 1, transform: 'scale(1.0) translate3d( 0, 0, 0) perspective(1px)' },
    delay: 500,
    config: { ...config.molasses },
    onRest: () => setToggleMsgScroll(true),
  });

  const animateText = () => {
    return transitions((style, item) => <animated.div style={style}>{item}</animated.div>);
  };

  const animateMobileText = () => {
    return mobileTransitions(
      (props, item, key) =>
        item && (
          <animated.div style={props} key={key}>
            {item}
          </animated.div>
        )
    );
  };

  const introAnimeVariants = useBreakpointValue({ base: animateMobileText(), md: animateText(), lg: animateText() });
  const headerPlxVariants = useBreakpointValue({ base: headerMobilePlxData, md: headerPlxData, lg: headerPlxData });

  return (
    <Flex h='100vh' position='fixed' justify='center' align='center' w='100%' direction='column'>
      <Plx style={{ width: '100%' }} parallaxData={headerPlxVariants}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          {introAnimeVariants}
        </Flex>
        <AnimeImage
          position='absolute'
          zIndex='-1'
          top={['-50%', '-5%', '-5%']}
          right={['-95px', '-45px', '-45px']}
          w={['174px', '294px', '294px']}
          h={['260px', '387px', '387px']}
          src={hourglass}
        />
      </Plx>
      <Plx parallaxData={paragraphTwoPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column' display={['block', 'none', 'none']}>
          <Text
            textStyle='titleNormal'
            textAlign='center'
            fontSize='22px'
            lineHeight='30px'
            color='#FCFAF8'
            maxW='785px'
            mx='40px'>
            Yet, for many who have been traditionally left out, emerging digital tools are not truly adapted to their
            aspirations and realities.
          </Text>
          <AnimeImage position='absolute' left='-50%' src={ortheogrid} />
        </Flex>
      </Plx>
      <Plx parallaxData={paragraphThreePlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column' display={['block', 'none', 'none']}>
          <Text
            textStyle='titleNormal'
            textAlign='center'
            fontSize='22px'
            lineHeight='30px'
            maxW='785px'
            mx='40px'
            color='#FCFAF8'>
            We envision a future where women and last-mile communities can access digital financial services with a well
            of confidence, agency, and power.
          </Text>
          <Text
            textStyle='titleNormal'
            fontSize='22px'
            lineHeight='30px'
            color='#FCFAF8'
            mx='40px'
            mt='36px'
            textAlign={['center', 'left', 'left']}>
            But to build that future, we first need to imagine it.
          </Text>
        </Flex>
      </Plx>
    </Flex>
  );
}
