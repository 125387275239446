import React, { useState, useEffect } from 'react';
import { Text, Flex, Button, Divider, Image, useBreakpointValue } from '@chakra-ui/react';
import { animated, useSpring } from 'react-spring';
import { Link } from 'react-router-dom';
import Plx from 'react-plx';
import { addCSSOffset } from '../utils';
import IntroPageOne from '../Components/IntroPageOne';
import IntroPageTwo from '../Components/IntroPageTwo';
import IntroPageThree from '../Components/IntroPageThree';
import IntroPageFour from '../Components/IntroPageFour';
import IntroPageFive from '../Components/IntroPageFive';
import IntroFooter from '../Components/IntroFooter';
import changeplus from '../images/icons/changeplus-logo.svg';

const bgPlxData = [
  {
    start: 'self',
    startOffset: '515vh',
    duration: '45vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.0,
        property: 'opacity',
      },
    ],
  },
];

function Intro() {
  const pageStartOffset = useBreakpointValue({ base: 100, md: 0, lg: 0 }); //start all page animations 100vh later for mobile
  const scrollMsgDelay = useBreakpointValue({ base: 0, md: 3000, lg: 3000 });

  useEffect(() => {
    if (pageStartOffset) {
      bgPlxData[0].startOffset = addCSSOffset(bgPlxData[0].startOffset, pageStartOffset);
    }
  }, [pageStartOffset]);
  const [toggleMsgScroll, setToggleMsgScroll] = useState(false);
  const scrollMsgStyles = useSpring({ delay: scrollMsgDelay, opacity: toggleMsgScroll ? 1 : 0 });
  const animateScrollMsg = () => {
    return (
      <animated.div style={scrollMsgStyles}>
        <Flex direction='column' align='center' position='fixed' bottom='0' width='100%'>
          <Text textStyle='sansBold' fontSize='14px' textTransform='uppercase'>
            Scroll Down
          </Text>
          <Divider
            borderColor='#000'
            borderLeftWidth='2px'
            orientation='vertical'
            h={['38px', '128px', '128px']}
            mt='8px'
          />
        </Flex>
        <Flex direction='column' align='flex-end' position='fixed' bottom='0' width='100%'>
          <Button
            as={Link}
            to='/home'
            mb={['68px', '113px', '113px']}
            mr={['24px', '118px', '118px']}
            w='91px'
            h='58px'
            _hover={{ bg: '#000', color: '#fff' }}
            _active={{ bg: '#000', color: '#fff' }}
            variant='outline'
            border='2px solid'
            borderColor='#000'
            borderRadius='50px'
            textTransform='uppercase'>
            Skip
          </Button>
        </Flex>
      </animated.div>
    );
  };

  return (
    <Flex h={['750vh', '650vh', '650vh']} bgColor='#D9B17D' justify='center' align='center' direction='column'>
      <Flex
        as={Plx}
        h={['650vh', '600vh', '600vh']}
        width='100%'
        position='absolute'
        top='0'
        parallaxData={bgPlxData}
        bg='linear-gradient(180deg, #698C94 0%, #789553 17.45%, #DC813F 32.05%, #BC6B8F 55.97%, #698C94 77.47%, #B3C799 97.53%)'>
        <Flex
          direction='column'
          align={['center', 'start', 'start']}
          position='fixed'
          top='0'
          mt='30px'
          ml={['0px', '60px', '60px']}
          width='100%'>
          <Image src={changeplus} />
        </Flex>

        <IntroPageOne setToggleMsgScroll={setToggleMsgScroll} />
        <IntroPageTwo />
        <IntroPageThree />
        <IntroPageFour />
        <IntroPageFive />
        {animateScrollMsg()}
      </Flex>

      <IntroFooter />
    </Flex>
  );
}

export default Intro;
