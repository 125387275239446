import React, { useEffect } from 'react';
import { Text, Flex, Image, Button, useBreakpointValue } from '@chakra-ui/react';
import Plx from 'react-plx';
import { Link } from 'react-router-dom';
import { addCSSOffset } from '../utils';
import AnimeImage from '../Components/AnimeImage';
import changeplus from '../images/icons/changeplus-logo.svg';
import hourglass from '../images/icons/hourglass-02.svg';
import ortheogrid from '../images/icons/ortheo-grid-02.svg';
import edgeblob from '../images/icons/blurred-ellipse.svg';

const footerPlxData = [
  {
    start: 'self',
    startOffset: '535vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
      {
        startValue: 450,
        endValue: 0,
        property: 'translateY',
      },
    ],
  },
];

const footerButtonPlxData = [
  {
    start: 'self',
    startOffset: '540vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export default function IntroFooter() {
  const pageStartOffset = useBreakpointValue({ base: 120, md: 0, lg: 0 }); //start all page animations 120vh later for mobile

  useEffect(() => {
    if (pageStartOffset) {
      footerPlxData[0].startOffset = addCSSOffset(footerPlxData[0].startOffset, pageStartOffset);
      footerButtonPlxData[0].startOffset = addCSSOffset(footerButtonPlxData[0].startOffset, pageStartOffset);
    }
  }, [pageStartOffset]);
  return (
    <Flex h='100vh' position='fixed' top='0' justify='center' align='center' direction='column'>
      <Plx parallaxData={footerPlxData} style={{ opacity: 0, width: '100%', zIndex: -1 }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Text
            textStyle='titleNormal'
            textAlign='center'
            fontSize={['28px', '75px', '75px']}
            lineHeight={['60px', '168px', '168px']}
            color='#000'
            maxW='784px'>
            Let’s make
          </Text>
          <Image w={['255px', '784px', '748px']} h={['62px', '187px', '187px']} zIndex='1' src={changeplus} />
        </Flex>
        <AnimeImage
          position='absolute'
          top={['-250%', '-30%', '-30%']}
          left={['-75%', '-75%', '-75%']}
          width={['562px', '562px', '562px']}
          height={['430px', '430px', '430px']}
          src={ortheogrid}
        />
      </Plx>
      <Plx parallaxData={footerButtonPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Button
            as={Link}
            to='/home'
            mt={['86px', '131px', '131px']}
            w='233px'
            h='58px'
            _hover={{ bg: '#000', color: '#fff' }}
            _active={{ bg: '#000', color: '#fff' }}
            variant='outline'
            border='2px solid'
            borderColor='#000'
            borderRadius='50px'>
            <Text textStyle='sansBold' textTransform='uppercase' fontSize='18px' lineHeight='26px' pt='3px'>
              Explore
            </Text>
          </Button>
        </Flex>
        <AnimeImage
          position='absolute'
          top={['-175%', '-55%', '-55%']}
          right={['-35%', '-50%', '-50%']}
          width={['unset', '294px', '294px']}
          height={['200px', '387px', '387px']}
          direction='reverse'
          src={hourglass}
        />
        <Image position='absolute' bottom='-250%' right='25px' zIndex='-1' src={edgeblob} />
      </Plx>
    </Flex>
  );
}
