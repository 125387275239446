import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Text, Box, Link, useBreakpointValue, Collapse } from '@chakra-ui/react';
import styled from '@emotion/styled';

const MenuLink = styled(RouterLink)`
  color: #000;
  text-decoration: none;
  border-top: transparent solid 3px;
  border-bottom: transparent solid 4px;
  height: min-content;
  &:focus,
  &:hover,
  &:active {
    border-bottom: #000 solid 4px;
  }
`;

const MobileMenuLink = styled(MenuLink)`
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
`;

const MenuExternalLink = styled(Link)`
  color: #000;
  text-decoration: none !important;
  border-top: transparent solid 3px;
  border-bottom: transparent solid 4px;
  height: min-content;
  padding-top: ${(props) => (props.mobileMenu ? '5vh' : 'unset')};
  padding-bottom: ${(props) => (props.mobileMenu ? '5vh' : 'unset')};
  width: ${(props) => (props.mobileMenu ? '100%' : 'unset')};
  &:focus,
  &:hover,
  &:active {
    border-bottom: #000 solid 4px;
  }
`;

function MenuLinkSelect(props) {
  const { mobile, toggle, to, children } = props;
  if (mobile) {
    return (
      <MobileMenuLink to={to} onClick={toggle}>
        {children}
      </MobileMenuLink>
    );
  }
  return (
    <MenuLink to={to} onClick={toggle}>
      {children}
    </MenuLink>
  );
}

function GlobalNavLinks({ toggle, isOpen }) {
  const mobileMenu = useBreakpointValue({ base: true, md: false, lg: false });

  const renderMenu = () => {
    return (
      <Box
        flexBasis={{ sm: '100%', md: '50%', lg: '50%' }}
        height={['100%', 'unset', 'unset']}
        width={['100%', 'unset', 'unset']}>
        <Flex
          direction={['column', 'row', 'row']}
          justify={['center', 'space-around', 'space-around']}
          h={['100%', 'unset', 'unset']}
          w='100%'
          pt={['4px,0px,0px']}>
          <Flex
            w={['100%', 'unset', 'unset']}
            h={['auto', 'unset', 'unset']}
            maxH='150px'
            align='center'
            justify='center'>
            <MenuLinkSelect to='/future-world' mobile={mobileMenu} toggle={toggle}>
              <Text
                textStyle='sansBold'
                textTransform='uppercase'
                minW='max-content'
                textAlign='center'
                fontSize='18px'
                mt='3px'>
                Future World
              </Text>
            </MenuLinkSelect>
          </Flex>
          <Flex
            w={['100%', 'unset', 'unset']}
            h={['auto', 'unset', 'unset']}
            maxH='150px'
            align='center'
            justify='center'>
            <MenuLinkSelect to='/projects' mobile={mobileMenu} toggle={toggle}>
              <Text
                textStyle='sansBold'
                textTransform='uppercase'
                minW='max-content'
                textAlign='center'
                fontSize='18px'
                mt='3px'>
                Projects
              </Text>
            </MenuLinkSelect>
          </Flex>
          <Flex
            w={['100%', 'unset', 'unset']}
            h={['auto', 'unset', 'unset']}
            maxH='150px'
            align='center'
            justify='center'>
            <MenuLinkSelect to='/about' mobile={mobileMenu} toggle={toggle}>
              <Text
                textStyle='sansBold'
                textTransform='uppercase'
                minW='max-content'
                textAlign='center'
                fontSize='18px'
                mt='3px'>
                About
              </Text>
            </MenuLinkSelect>
          </Flex>
          <Flex
            w={['100%', 'unset', 'unset']}
            h={['auto', 'unset', 'unset']}
            maxH='150px'
            align='center'
            justify='center'>
            <MenuExternalLink
              onClick={toggle}
              mobileMenu={mobileMenu}
              target='_blank'
              rel='noopener noreferrer'
              href='https://event.changeplus.design/'>
              <Text
                textStyle='sansBold'
                textTransform='uppercase'
                minW='max-content'
                textAlign='center'
                fontSize='18px'
                mt='6px'>
                RSVP
              </Text>
            </MenuExternalLink>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return mobileMenu ? (
    <Collapse in={isOpen} style={{ width: '100%' }} animateOpacity>
      {renderMenu()}
    </Collapse>
  ) : (
    renderMenu()
  );
}

export default GlobalNavLinks;
