import React, { useState, useEffect } from 'react';
import { Center, Image, Box, Text, Flex, Spinner, useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useSpring, animated, config } from 'react-spring';
import CharacterButton from '../Components/CharacterButton';
import CharacterNameBubble from '../Components/CharacterNameBubble';
import { useAppContext } from '../Components/Store';
import star from '../images/icons/star-outline-white.svg';
import stardark from '../images/icons/mural-star.svg';
import muralellipse from '../images/icons/mural-ellipse.svg';
import environment from '../images/environment.png';

const GradientBg = styled(Center)`
  background: linear-gradient(
    180deg,
    #d7b7c6 0%,
    #fffaf1 15.51%,
    #eebda7 26.37%,
    #dfb6c4 38.15%,
    #cbdfe1 51.95%,
    #d1dbaf 64.1%,
    #fff9f1 78.09%,
    #efdee0 88.4%,
    #efc1ac 100%
  );
  background-size: 600% 600%;

  -webkit-animation: ScrollAnime 40s linear infinite;
  -moz-animation: ScrollAnime 40s linear infinite;
  animation: ScrollAnime 40s linear infinite;

  @-webkit-keyframes ScrollAnime {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 51% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @-moz-keyframes ScrollAnime {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 51% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @keyframes ScrollAnime {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 51% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
`;

function Ecosystem() {
  const tutorialTextBottom = useBreakpointValue({
    base: 'calc(20vh - 100px)',
    md: 'calc(20vh - 100px)',
    lg: 'calc(94vh - 100px)',
  });
  const tutorialTextBottomAfter = useBreakpointValue({
    base: 'calc(20vh - 100px)',
    md: 'calc(20vh - 100px)',
    lg: 'calc(13vh - 100px)',
  });
  const tutorialTextWidth = useBreakpointValue({ base: '85vw', md: '29vw', lg: '29vw' });
  const tutorialTextSizeBefore = useBreakpointValue({ base: '13px', md: '22px', lg: '22px' });
  const tutorialTextSizeAfter = useBreakpointValue({ base: '13px', md: '17px', lg: '17px' });
  const tutorialLineHeightBefore = useBreakpointValue({ base: '19.5px', md: '37px', lg: '37px' });
  const tutorialLineHeightAfter = useBreakpointValue({ base: '19.5px', md: '27px', lg: '27px' });
  const tutorialTextLeft = useBreakpointValue({ base: '9vw', md: '92px', lg: '92px' });

  const [state, dispatch] = useAppContext();
  const [timeCount, setTimeCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const animeStyles = useSpring({
    opacity: state.tutorialStatus ? 0.75 : 0.0,
    zIndex: state.tutorialStatus ? 2 : 0,
    pointerEvents: 'none',
    config: { ...config.molasses },
  });

  const animeTextStyles = useSpring({
    position: 'absolute',
    display: 'flex',
    pointerEvents: 'none',
    bottom: state.tutorialStatus ? tutorialTextBottom : tutorialTextBottomAfter,
    left: tutorialTextLeft,
    maxWidth: state.tutorialStatus ? '100vw' : tutorialTextWidth,
    color: state.tutorialStatus ? '#fff' : '#000',
    config: { ...config.molasses },
  });

  useEffect(() => {
    if (state.tutorialStatus) {
      const now = new Date();
      var milliseconds = now.getMilliseconds();
      var newTimeout = 1000 - milliseconds;
      const timer = setTimeout(() => {
        setTimeCount(timeCount + 1);
      }, newTimeout);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeCount, state.tutorialStatus]);

  useEffect(() => {
    if (timeCount >= 10) {
      dispatch({
        type: 'SET_TUTORIAL_STATUS',
        payload: false,
      });
    }
  }, [timeCount]);

  return (
    <GradientBg
      h={['calc(100vh - 80px)', 'calc(100vh - 100px)', 'calc(100vh - 100px)']}
      w={['280vw', '100vw', '100vw']}>
      <animated.div style={animeStyles}>
        <Box
          position='absolute'
          top='0'
          left='0'
          pointerEvents='none'
          w='100%'
          h={['calc(100vh - 80px)', 'calc(100vh - 100px)', 'calc(100vh - 100px)']}
          bgColor='rgba(0,0,0,0.75)'></Box>
      </animated.div>
      {isLoaded && (
        <animated.div style={animeTextStyles}>
          <Image
            zIndex='3'
            w={['20px', '27px', '27px']}
            h={['20px', '27px', '27px']}
            display={state.tutorialStatus ? 'inline' : 'none'}
            pb={['0px', '0px', '0px']}
            mb={['5px', '10px', '10px']}
            mr={['5px', '10px', '10px']}
            src={star}
          />
          <Image
            zIndex='3'
            w={['20px', '27px', '27px']}
            h={['20px', '27px', '27px']}
            display={state.tutorialStatus ? 'none' : 'inline'}
            pb={['5px', '5px', '5px']}
            mb={['5px', '10px', '10px']}
            mr={['5px', '10px', '10px']}
            src={stardark}
          />
          <Text
            textStyle='sansNormal'
            zIndex='3'
            fontSize={state.tutorialStatus ? tutorialTextSizeBefore : tutorialTextSizeAfter}
            lineHeight={state.tutorialStatus ? tutorialLineHeightBefore : tutorialLineHeightAfter}>
            Click on any of the scenes to learn about the lives of Reza, Consolata and Sumaiya in 2030
          </Text>
        </animated.div>
      )}

      <Flex position='relative' mx='5%' mt='70px' mb='50px'>
        {!isLoaded && (
          <Flex>
            <Spinner color='#fff' size='xl' zIndex='2' />
          </Flex>
        )}
        <Flex>
          <Image w='100%' h='100%' src={environment} zIndex='1' onLoad={() => setIsLoaded(true)} />

          {isLoaded && (
            <>
              <CharacterButton
                to='/reza?scene=1'
                name='Reza'
                top='11.9%'
                left='11.4%'
                w='8.1%'
                h='15.5%'
                spotlight={<Image position='relative' zIndex='1' top='70%' left='0%' maxW='110%' src={muralellipse} />}
                active={state.tutorialStatus}
                imgSrc='images/mural/Reza-01-black-mural-web.png'
              />
              <CharacterButton
                to='/reza?scene=3'
                name='Reza'
                top='14.4%'
                left='27.7%'
                w='5.5%'
                h='11.3%'
                spotlight={
                  <Image position='relative' zIndex='1' top='60%' left='-20%' maxW='140%' src={muralellipse} />
                }
                active={state.tutorialStatus}
                imgSrc='images/mural/Reza-03-black-mural-web.png'
              />
              <CharacterButton
                to='/reza?scene=2'
                name='Reza'
                top='44.8%'
                left='24.3%'
                w='7.6%'
                h='15.9%'
                spotlight={
                  <Image position='relative' zIndex='1' top='65%' left='-10%' maxW='130%' src={muralellipse} />
                }
                active={state.tutorialStatus}
                imgSrc='images/mural/Reza-02-black-mural-web.png'
              />
              <CharacterButton
                to='/reza?scene=4'
                name='Reza'
                top='43.5%'
                left='37.6%'
                w='4.7%'
                h='14.7%'
                spotlight={
                  <Image position='relative' zIndex='1' top='80%' left='-20%' maxW='130%' src={muralellipse} />
                }
                active={state.tutorialStatus}
                imgSrc='images/mural/Reza-00-black-mural-web.png'
              />

              <CharacterButton
                to='/consolata?scene=1'
                name='Consolata'
                top='24.5%'
                left='46.3%'
                w='8.3%'
                h='16.2%'
                spotlight={<Image position='relative' zIndex='1' top='70%' left='-30%' src={muralellipse} />}
                active={state.tutorialStatus}
                imgSrc='images/mural/Consolata-01-black-mural-web.png'
              />
              <CharacterButton
                to='/consolata?scene=4'
                name='Consolata'
                top='31.1%'
                left='58.4%'
                w='4.4%'
                h='14.2%'
                spotlight={
                  <Image position='relative' zIndex='1' top='80%' left='-15%' maxW='130%' src={muralellipse} />
                }
                active={state.tutorialStatus}
                imgSrc='images/mural/Consolata-00-black-mural-web.png'
              />
              <CharacterButton
                to='/consolata?scene=3'
                name='Consolata'
                top='26.1%'
                left='66.3%'
                w='13.9%'
                h='14.8%'
                spotlight={<Image position='relative' zIndex='1' top='65%' left='55%' maxW='60%' src={muralellipse} />}
                active={state.tutorialStatus}
                imgSrc='images/mural/Consolata-03-black-mural-web.png'
              />
              <CharacterButton
                to='/consolata?scene=2'
                name='Consolata'
                top='1.3%'
                left='90.6%'
                w='5.3%'
                h='13.6%'
                spotlight={
                  <Image position='relative' zIndex='1' top='80%' left='-20%' maxW='130%' src={muralellipse} />
                }
                active={state.tutorialStatus}
                imgSrc='images/mural/Consolata-02-black-mural-web.png'
              />

              <CharacterButton
                to='/sumaiya?scene=1'
                name='Sumaiya'
                top='61.6%'
                left='51.1%'
                w='10.9%'
                h='14.4%'
                spotlight={<Image position='relative' zIndex='1' top='20%' left='-5%' maxW='80%' src={muralellipse} />}
                active={state.tutorialStatus}
                imgSrc='images/mural/Sumaiya-01-black-mural-web.png'
              />
              <CharacterButton
                to='/sumaiya?scene=4'
                name='Sumaiya'
                top='53.8%'
                left='66.8%'
                w='4.2%'
                h='14.0%'
                spotlight={
                  <Image position='relative' zIndex='1' top='80%' left='-15%' maxW='130%' src={muralellipse} />
                }
                active={state.tutorialStatus}
                imgSrc='images/mural/Sumaiya-00-black-mural-web.png'
              />
              <CharacterButton
                to='/sumaiya?scene=2'
                name='Sumaiya'
                top='66.3%'
                left='80.4%'
                w='15.0%'
                h='20.6%'
                spotlight={<Image position='relative' zIndex='1' top='75%' left='-5%' maxW='70%' src={muralellipse} />}
                active={state.tutorialStatus}
                imgSrc='images/mural/Sumaiya-02-black-mural-web.png'
              />
              <CharacterButton
                to='/sumaiya?scene=3'
                name='Sumaiya'
                top='50.6%'
                left='76.6%'
                width='9.0%'
                height='14.0%'
                spotlight={<Image position='relative' zIndex='1' top='65%' left='15%' src={muralellipse} />}
                active={state.tutorialStatus}
                imgSrc='images/mural/Sumaiya-03-black-mural-web.png'
              />

              <CharacterNameBubble
                name='Reza'
                active={state.tutorialStatus}
                top='27.8%'
                left='17.7%'
                width='19.5%'
                height='13%'
              />
              <CharacterNameBubble
                name='Consolata'
                active={state.tutorialStatus}
                top='10.5%'
                left='52.1%'
                width='24.8%'
                height='14%'
              />
              <CharacterNameBubble
                name='Sumaiya'
                active={state.tutorialStatus}
                top='67.8%'
                left='30.2%'
                width='19.9%'
                height='13%'
              />
            </>
          )}
        </Flex>
      </Flex>
    </GradientBg>
  );
}

export default Ecosystem;
