import React from 'react';

/** ********************************************************** */
//
//  useFetch
//
// const { response,error,isLoading } = useFetch("https://something.to.fetch", options={},[]);
//
/** ********************************************************** */
export const useFetch = (url, options, deps) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
        setIsLoading(false);
      } catch (err) {
        setError(err);
      }
    };
    fetchData();
  }, [...deps]);
  return {
    response,
    error,
    isLoading,
  };
};

export const addCSSOffset = (position, offset) => {
  const unitsArray = ['px', 'vh', 'vw', 'em', 'rem', '%'];
  const unitsRegex = new RegExp(unitsArray.join('|'), 'gm');
  const unit = position.match(unitsRegex)[0];
  const offsetValue = parseInt(position.replace(unitsRegex, ''));
  return offsetValue + offset + unit;
};
