import React from 'react';
import { Text, Image } from '@chakra-ui/react';
import { useSpring, animated, config } from 'react-spring';
import { useAppContext } from '../Components/Store';
import muralellipse from '../images/icons/mural-ellipse.svg';

function CharacterNameBubble(props) {
  const { name, active, ...positions } = props;

  const [state, dispatch] = useAppContext();

  const characterState = (name) => {
    switch (name) {
      case 'Sumaiya':
        return state.hoverSumaiya;
      case 'Reza':
        return state.hoverReza;
      case 'Consolata':
        return state.hoverConsolata;
      default:
        return '';
    }
  };

  const animeStyles = useSpring({
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...positions,
    opacity: characterState(name) || active ? 1.0 : 0.0,
    config: { ...config.stiff },
  });

  return (
    <animated.div style={animeStyles}>
      <Image position='relative' w='100%' h='100%' filter='blur(4.8px)' src={muralellipse} />
      <Text position='absolute' textAlign='center' textStyle='titleNormal' fontSize={['42px', '50px', '50px']}>
        {name}
      </Text>
    </animated.div>
  );
}

export default CharacterNameBubble;
