import React, { useState } from 'react';
import { Button, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring';
import styled from '@emotion/styled';
import { useAppContext } from '../Components/Store';

const AnimeDiv = styled(animated.div)`
  -webkit-animation: animeBlur 1.5s ease-in-out infinite;
  -moz-animation: animeBlur 1.5s ease-in-out infinite;
  animation: animeBlur 1.5s ease-in-out infinite;

  @-webkit-keyframes animeBlur {
    0% {
      -webkit-filter: blur(2px);
    }
    50% {
      -webkit-filter: blur(4.8px);
    }
    100% {
      -webkit-filter: blur(2px);
    }
  }
  @-moz-keyframes animeBlur {
    0% {
      -moz-filter: blur(2px);
    }
    50% {
      -moz-filter: blur(4.8px);
    }
    100% {
      -moz-filter: blur(2px);
    }
  }

  @keyframes animeBlur {
    0% {
      filter: blur(2px);
    }
    50% {
      filter: blur(4.8px);
    }
    100% {
      filter: blur(2px);
    }
  }
`;

function CharacterButton(props) {
  const { to, imgSrc, name, spotlight, active = false, ...positions } = props;
  const [isHover, setIsHover] = useState(false);
  const [state, dispatch] = useAppContext();

  const animeStyles = useSpring({
    position: 'absolute',
    zIndex: '1',
    bottom: '0px',
    width: '100%',
    height: '100%',
    opacity: isHover || active ? 1.0 : 0.0,
    config: { ...config.stiff },
  });

  const handleHover = (status) => {
    setIsHover(status);
    switch (name) {
      case 'Sumaiya':
        dispatch({
          type: 'SET_HOVER_SUMAIYA',
          payload: status,
        });
        break;
      case 'Reza':
        dispatch({
          type: 'SET_HOVER_REZA',
          payload: status,
        });
        break;
      case 'Consolata':
        dispatch({
          type: 'SET_HOVER_CONSOLATA',
          payload: status,
        });
        break;
      default:
        return '';
    }
  };

  return (
    <Button
      as={Link}
      to={to}
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover(false)}
      variant='unstyled'
      position='absolute'
      zIndex='2'
      {...positions}>
      <Image position='relative' zIndex='3' src={imgSrc} />
      <AnimeDiv style={animeStyles}>{spotlight}</AnimeDiv>
    </Button>
  );
}

export default CharacterButton;
