import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';

function GlobalNavToggle({ isOpen, toggle }) {
  return (
    <Box display={{ base: 'block', md: 'none' }} h='40px' onClick={toggle}>
      {isOpen ? <Icon as={MdClose} w='40px' h='40px' /> : <Icon as={MdMenu} w='40px' h='40px' />}
    </Box>
  );
}

export default GlobalNavToggle;
