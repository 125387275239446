import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import GlobalNavLinks from './GlobalNavLinks';
import GlobalNavToggle from './GlobalNavToggle';
import changeplus from '../images/icons/changeplus-logo.svg';

const MenuLink = styled(Link)`
  color: #000;
  text-decoration: none;
  border-top: transparent solid 3px;
  border-bottom: transparent solid 4px;
  &:focus,
  &:hover,
  &:active {
    border-bottom: #000 solid 4px;
  }
`;

function GlobalNav() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Box bg='#DC813F' w='100%' h={['80px', '100px', '100px']} mt='0' position='fixed' zIndex='999' top='0' left='0'>
      <Flex
        as='nav'
        direction='row'
        justify='space-between'
        align='center'
        w='100%'
        wrap='wrap'
        mb={8}
        px={['20px', 8, 8]}
        pt={[3, 5, 5]}
        bg={['#DC813F', '#DC813F', 'transparent', 'transparent']}>
        <Box>
          <MenuLink to='/home' onClick={() => setIsOpen(false)}>
            <Image src={changeplus} alt='change+' my='5px' ml={['5px', '60px', '60px']} />
          </MenuLink>
        </Box>
        <GlobalNavToggle toggle={toggle} isOpen={isOpen} />
        <GlobalNavLinks toggle={toggle} isOpen={isOpen} />
      </Flex>
    </Box>
  );
}

export default GlobalNav;
