import React, { useEffect } from 'react';
import { Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import Plx from 'react-plx';
import { addCSSOffset } from '../utils';
import AnimeImage from '../Components/AnimeImage';
import curvedslope from '../images/icons/curved-slope.svg';
import cone from '../images/icons/cone.svg';

const headerPlxData = [
  {
    start: 'self',
    startOffset: '185vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
      {
        startValue: 500,
        endValue: 0,
        property: 'translateY',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '285vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

const YearPlxData = [
  {
    start: 'self',
    startOffset: '195vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '260vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

export default function IntroPageThree() {
  const pageStartOffset = useBreakpointValue({ base: 130, md: 0, lg: 0 }); //start all page animations 120vh later for mobile

  useEffect(() => {
    if (pageStartOffset) {
      headerPlxData[0].startOffset = addCSSOffset(headerPlxData[0].startOffset, pageStartOffset);
      headerPlxData[1].startOffset = addCSSOffset(headerPlxData[1].startOffset, pageStartOffset);

      // console.log(YearPlxData[0].startOffset, YearPlxData[1].startOffset);
      YearPlxData[0].startOffset = addCSSOffset(YearPlxData[0].startOffset, pageStartOffset);
      YearPlxData[1].startOffset = addCSSOffset(YearPlxData[1].startOffset, pageStartOffset);

      // console.log(YearPlxData[0].startOffset, YearPlxData[1].startOffset);
    }
  }, [pageStartOffset]);

  return (
    <Flex h='100vh' position='fixed' w='100%' justify='center' align='center' direction='column'>
      <Plx parallaxData={headerPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Text
            textStyle='titleNormal'
            textAlign='center'
            fontSize={['20px', '20px', '28px']}
            lineHeight={['30px', '30px', '38px']}
            m={['24px', 'unset', 'unset']}
            mx={['40px', 'unset', 'unset']}
            maxW='710px'
            color='#FCFAF8'>
            Women have the agency to make their own financial decisions, because the digital economy is actually
            designed for them.
          </Text>
        </Flex>
        <AnimeImage
          position='absolute'
          top={['70%', '-30%', '-30%']}
          right={['-100px', '-121px', '-121px']}
          w={['180px', '314px', '314px']}
          h={['180px', '314px', '314px']}
          direction='reverse'
          src={curvedslope}
        />
      </Plx>
      <Plx parallaxData={YearPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Text
            mt={['35px', '35px', '58px']}
            textStyle='titleNormal'
            textAlign='center'
            fontSize={['20px', '20px', '28px']}
            lineHeight={['30px', '30px', '38px']}
            display='block'
            mx={['40px', 'unset', 'unset']}
            color='#FCFAF8'>
            Digital services meet people where they are.
          </Text>
        </Flex>
        <AnimeImage
          position='absolute'
          top='270%'
          left='20%'
          w={['197px', '344px', '344px']}
          h={['170px', '298px', '298px']}
          src={cone}
        />
      </Plx>
    </Flex>
  );
}
