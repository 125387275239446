import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  textStyles: {
    sansNormal: {
      fontFamily:
        'poppins-regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell,"Helvetica Neue", sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    sansMedium: {
      fontFamily:
        'poppins-medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell,"Helvetica Neue", sans-serif',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    sansBold: {
      fontFamily:
        'poppins-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell,"Helvetica Neue", sans-serif',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    titleNormal: {
      fontFamily:
        'amphora-regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell,"Helvetica Neue", sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
});

export default theme;
