import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB8F0MYPEE40AvACsPXAJx8SJ3JNwYUMOo',
  authDomain: 'changeplus.firebaseapp.com',
  projectId: 'changeplus',
  storageBucket: 'changeplus.appspot.com',
  messagingSenderId: '230653635037',
  appId: '1:230653635037:web:23c4200ce20532e61fee34',
  measurementId: 'G-3V2K3V55WQ',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const firebaseDb = getFirestore();

export const logGAEvent = (screenName) => {
  logEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
  });
};

//collection = votes
//doc = project name
//msg update = loveCount buildCount

export const getLoveVoteCount = async (project) => {
  const docRef = doc(firebaseDb, 'votes', project);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().loveCount;
  } else {
    console.warn(`Document: ${project} doesn't exist!`);
  }
};
export const getBuildVoteCount = async (project) => {
  const docRef = doc(firebaseDb, 'votes', project);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().buildCount;
  } else {
    console.warn(`Document: ${project} doesn't exist!`);
  }
};

export const addLoveVote = async (project, count = 1) => {
  const docRef = doc(firebaseDb, 'votes', project);
  await updateDoc(docRef, {
    loveCount: increment(count),
  });
};

export const addBuildVote = async (project, count = 1) => {
  const docRef = doc(firebaseDb, 'votes', project);
  await updateDoc(docRef, {
    buildCount: increment(count),
  });
};

// export const addSiteView = () => {
//   return firebaseDb.collection('site').doc('views').update('count', firebase.firestore.FieldValue.increment(1));
// };

// export const getSiteViewCount = () => {
//   return firebaseDb.collection('site').doc('views').get();
// };

// export const getSiteUserCount = () => {
//   return firebaseDb.collection('site').doc('users').get();
// };

// export const getCurrentUser = () => firebase.auth().currentUser;

// export const getIdTokenResult = (b) => firebase.auth().currentUser.getIdTokenResult(b);

// export const getConfig = () => {
//   return {
//     providers: {
//       googleProvider: new firebase.auth.GoogleAuthProvider(),
//     },
//     firebaseAppAuth: firebaseApp.auth(),
//   };
// };
