import React, { useEffect } from 'react';
import { Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import Plx from 'react-plx';
import { addCSSOffset } from '../utils';
import AnimeImage from '../Components/AnimeImage';
import cone from '../images/icons/cone.svg';
import curvedslope from '../images/icons/curved-slope-02.svg';
import pyramid2 from '../images/icons/pyramid-02.svg';

const headerPlxData = [
  {
    start: 'self',
    startOffset: '395vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
      {
        startValue: 500,
        endValue: 0,
        property: 'translateY',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '485vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

export default function IntroPageFive() {
  const pageStartOffset = useBreakpointValue({ base: 130, md: 0, lg: 0 }); //start all page animations 120vh later for mobile

  useEffect(() => {
    if (pageStartOffset) {
      headerPlxData[0].startOffset = addCSSOffset(headerPlxData[0].startOffset, pageStartOffset);
      headerPlxData[1].startOffset = addCSSOffset(headerPlxData[1].startOffset, pageStartOffset);
    }
  }, [pageStartOffset]);

  return (
    <Flex h='100vh' position='fixed' w='100%' justify='center' align='center' direction='column'>
      <Plx parallaxData={headerPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Text
            textStyle='titleNormal'
            textAlign='center'
            fontSize='28px'
            lineHeight='38px'
            maxW='590px'
            mx={['60px', 'unset', 'unset']}
            color='#FCFAF8'>
            We believe this future is not only possible, it’s already in the making.
          </Text>
        </Flex>
        <AnimeImage
          position='absolute'
          display={['block', 'none', 'none']}
          top='-220%'
          left='-10%'
          w='180px'
          h='155px'
          transform='rotate(19.4deg)'
          src={cone}
        />
        <AnimeImage
          position='absolute'
          top={['-55%', '-15%', '-15%']}
          right={['-16%', '-6%', '-6%']}
          w={['154px', '306px', '306px']}
          h={['169px', '355px', '335px']}
          direction='reverse'
          src={pyramid2}
        />
        <AnimeImage
          position='absolute'
          top={['200%', '-90%', '-90%']}
          left={['-20%', '-2%', '-2%']}
          w={['198px', '379px', '379px']}
          h={['208px', '397px', '397px']}
          src={curvedslope}
        />
      </Plx>
    </Flex>
  );
}
