import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export default function ScrollBox(props) {
  const contentBoxRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/future-world') {
      const elm = contentBoxRef.current;
      contentBoxRef.current.scrollTo(elm.scrollWidth / 2 - elm.offsetWidth / 2, 0);
    }
  }, [pathname]);

  return (
    <Box
      ref={contentBoxRef}
      mt={['0px', '100px', '100px']}
      overflowX={['scroll', 'hidden', 'hidden']}
      overflowY={['hidden', 'hidden', 'hidden']}>
      {props.children}
    </Box>
  );
}
