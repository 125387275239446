import React, { createContext, useReducer, useContext } from 'react';

// global state variables with intitalstates
const initialState = {
  tutorialStatus: true, //did you see the mural tutorial yet?
  hoverSumaiya: false,
  hoverReza: false,
  hoverConsolata: false,
};

// the state reducer
export const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TUTORIAL_STATUS':
      return {
        ...state,
        tutorialStatus: action.payload,
      };
    case 'SET_HOVER_SUMAIYA':
      return {
        ...state,
        hoverSumaiya: action.payload,
      };
    case 'SET_HOVER_REZA':
      return {
        ...state,
        hoverReza: action.payload,
      };
    case 'SET_HOVER_CONSOLATA':
      return {
        ...state,
        hoverConsolata: action.payload,
      };
    default:
      return state;
  }
};

// The store function
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export default Store;
export const Context = createContext(initialState);
export const useAppContext = () => useContext(Context);
