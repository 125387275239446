import React, { useEffect } from 'react';
import { Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import Plx from 'react-plx';
import AnimeImage from '../Components/AnimeImage';
import { addCSSOffset } from '../utils';
import ortheogrid from '../images/icons/ortheo-grid.svg';

const headerPlxData = [
  {
    start: 'self',
    startOffset: '110vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
      {
        startValue: 100,
        endValue: -90,
        property: 'translateY',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '185vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: -90,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

const YearPlxData = [
  {
    start: 'self',
    startOffset: '120vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '175vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

export default function IntroPageTwo() {
  const pageStartOffset = useBreakpointValue({ base: 100, md: 0, lg: 0 }); //start all page animations 120vh later for mobile

  useEffect(() => {
    if (pageStartOffset) {
      headerPlxData[0].startOffset = addCSSOffset(headerPlxData[0].startOffset, pageStartOffset);
      headerPlxData[1].startOffset = addCSSOffset(headerPlxData[1].startOffset, pageStartOffset);

      // console.log(YearPlxData[0].startOffset, YearPlxData[1].startOffset);
      YearPlxData[0].startOffset = addCSSOffset(YearPlxData[0].startOffset, pageStartOffset);
      YearPlxData[1].startOffset = addCSSOffset(YearPlxData[1].startOffset, pageStartOffset);

      // console.log(YearPlxData[0].startOffset, YearPlxData[1].startOffset);
    }
  }, [pageStartOffset]);

  return (
    <Flex h='100vh' position='fixed' w='100%' justify='center' align='center' direction='column'>
      <Plx parallaxData={headerPlxData} style={{ opacity: 0 }}>
        <Text
          textStyle='titleNormal'
          fontSize={['20px', '20px', '28px']}
          lineHeight={['30px', '30px', '38px']}
          mx={['60px', 'unset', 'unset']}
          textAlign={['center', 'left', 'left']}
          color='#FCFAF8'>
          Take a seat and join us in some time travel.
        </Text>
      </Plx>
      <Plx parallaxData={YearPlxData} style={{ opacity: 0 }}>
        <Flex
          position='absolute'
          top={['-50%', '-10%', '-10%']}
          left={['-40vw', '-5vw', '-5vw']}
          width={['450px', '650px', '650px']}>
          <AnimeImage src={ortheogrid} />
        </Flex>
        <Text textStyle='titleNormal' textAlign='center' fontSize={['28px', '38px', '38px']} color='#FCFAF8'>
          The year is
        </Text>
        <Text textStyle='titleNormal' textAlign='center' fontSize={['75px', '200px', '200px']} color='#FCFAF8'>
          2030
        </Text>
      </Plx>
    </Flex>
  );
}
