import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Center, Spinner } from '@chakra-ui/react';
import theme from './theme';
import Store from './Components/Store';
import GlobalNav from './Components/GlobalNav';
import ScrollToTop from './Components/ScrollToTop';
import ScrollBox from './Components/ScrollBox';
import Intro from './Pages/Intro';
import Main from './Pages/Main';
import Ecosystem from './Pages/Ecosystem';
const CharacterSumaiya = lazy(() => import('./Pages/CharacterSumaiya'));
const CharacterReza = lazy(() => import('./Pages/CharacterReza'));
const CharacterConsolata = lazy(() => import('./Pages/CharacterConsolata'));
const Gallery = lazy(() => import('./Pages/Gallery'));
const About = lazy(() => import('./Pages/About'));

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Store>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path='/'>
              <Intro />
            </Route>
            <>
              <GlobalNav />
              <Suspense
                fallback={
                  <Center h='100vh'>
                    <Spinner size='xl' />
                  </Center>
                }>
                <Route path='/home'>
                  <Main />
                </Route>
                <Route path='/future-world'>
                  <ScrollBox>
                    <Ecosystem />
                  </ScrollBox>
                </Route>
                <Route path='/sumaiya'>
                  <CharacterSumaiya />
                </Route>
                <Route path='/reza'>
                  <CharacterReza />
                </Route>
                <Route path='/consolata'>
                  <CharacterConsolata />
                </Route>
                <Route path='/projects'>
                  <Gallery />
                </Route>
                <Route path='/about'>
                  <About />
                </Route>
              </Suspense>
            </>
          </Switch>
        </Router>
      </Store>
    </ChakraProvider>
  );
}

export default App;
