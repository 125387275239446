import React from 'react';
import ReactPlayer from 'react-player';
import { Center, Spinner, AspectRatio, Text } from '@chakra-ui/react';

function VideoPlayer(props) {
  const { src, width, type, poster } = props;
  console.log(poster);
  return (
    <AspectRatio w={width} ratio={16 / 9}>
      {type !== 'youtube' ? (
        <video controls playsInline width='100%' height='100%' poster={poster}>
          <source src={src} type={type} />
          <Text textStyle='sansNormal' fontSize='11px'>
            Your browser doesn't support HTML5 video. Here is a <a href={src}>link to the video</a> instead.
          </Text>
        </video>
      ) : (
        <ReactPlayer
          style={{ position: 'absolute' }}
          width='100%'
          height='100%'
          url={src}
          playing
          light
          fallback={
            <Center w='100%' h='100%'>
              <Spinner />
            </Center>
          }
        />
      )}
    </AspectRatio>
  );
}

export default VideoPlayer;
