import React from 'react';
import { Flex, Text, Icon, Button, Link, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { MdCallMade } from 'react-icons/md';
import ideologo from '../images/icons/IDEO-logo.png';
import ideoorglogo from '../images/icons/IDEO-org-logo.png';

const MenuLink = styled(RouterLink)`
  color: #000;
  width: max-content;
  text-decoration: none;
  border-top: transparent solid 3px;
  border-bottom: transparent solid 4px;
  height: min-content;
  &:focus,
  &:hover,
  &:active {
    border-bottom: #000 solid 4px;
  }
`;

export default function Footer(props) {
  return (
    <Flex h={[null, null, '433px']} direction={['column', 'column', 'row']} bgColor='#DC813F' {...props}>
      <Flex w={[null, null, '50vw']} m={['24px', '24px', '85px']} direction={['column', 'column', 'row']}>
        <Flex direction='column' justify={['center', 'center', 'space-around']} w='100%' pt={['4px', '4px', '0px']}>
          <MenuLink to='/home'>
            <Text textStyle='sansBold' fontSize='18px' my='5px'>
              Change+
            </Text>
          </MenuLink>
          <MenuLink to='/future-world'>
            <Text textStyle='sansBold' fontSize='18px' my='5px'>
              Future World
            </Text>
          </MenuLink>
          <MenuLink to='/projects'>
            <Text textStyle='sansBold' fontSize='18px' my='5px'>
              Projects
            </Text>
          </MenuLink>
          <MenuLink to='/about'>
            <Text textStyle='sansBold' fontSize='18px' my='5px'>
              About
            </Text>
          </MenuLink>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href='https://event.changeplus.design/'
            _hover={{
              textDecoration: 'underline',
              textDecorationColor: '#000',
              textDecorationThickness: '4px',
              textUnderlineOffset: '8px',
            }}>
            <Text textStyle='sansBold' fontSize='18px' my='5px'>
              RSVP
            </Text>
          </Link>
        </Flex>
        <Flex direction='column' align={['center', 'center', 'unset']}>
          <Text textStyle='titleNormal' fontSize={['40px', '40px', '75px']} mt={['24px', '24px', null]}>
            Questions?
          </Text>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href='mailto:change+workshop@ideo.org'
            _hover={{
              textDecoration: 'underline',
              textDecorationColor: '#000',
              textDecorationThickness: '4px',
              textUnderlineOffset: '8px',
            }}>
            <Text textStyle='sansBold' fontSize='18px' mt={['24px', '24px', null]}>
              change+workshop@ideo.org
            </Text>
          </Link>
          <Button
            as={Link}
            target='_blank'
            rel='noopener noreferrer'
            href='https://event.changeplus.design/'
            textDecoration='none!important'
            mt='32px'
            size='lg'
            maxW='273px'
            height='58px'
            _hover={{ bg: '#000', color: '#fff' }}
            _active={{ bg: '#000', color: '#fff' }}
            variant='outline'
            bgColor='#DC813F'
            color='#000'
            border={'2px'}
            borderColor='#000'
            borderRadius='50px'>
            <Text textStyle='sansBold' textTransform='uppercase' pt='3px'>
              RSVP to the event
            </Text>
            <Icon as={MdCallMade} w='24px' h='24px' ml='10px' />
          </Button>
        </Flex>
      </Flex>
      <Flex direction='column' mx='36px' flex='1' align={['center', 'center', 'end']}>
        <Flex
          w='212px'
          my={['50px', '50px', '85px']}
          mr={['unset', 'unset', '85px']}
          align={['center', 'center', 'end']}
          direction='column'>
          <Image src={ideoorglogo} />
          <Image mt='24px' w='123px' src={ideologo} />
        </Flex>
      </Flex>
    </Flex>
  );
}
