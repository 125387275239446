import React, { useEffect } from 'react';
import { Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import Plx from 'react-plx';
import { addCSSOffset } from '../utils';
import AnimeImage from '../Components/AnimeImage';
import cone from '../images/icons/cone.svg';
import ortheogrid from '../images/icons/ortheo-grid.svg';
import pyramid from '../images/icons/pyramid.svg';

const headerPlxData = [
  {
    start: 'self',
    startOffset: '305vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
      {
        startValue: 500,
        endValue: 0,
        property: 'translateY',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '385vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

const YearPlxData = [
  {
    start: 'self',
    startOffset: '310vh',
    duration: '200px',
    properties: [
      {
        startValue: 0.9,
        endValue: 1.0,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1.0,
        property: 'opacity',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '370vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1.0,
        endValue: 0.9,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 0,
        endValue: -300,
        property: 'translateY',
      },
    ],
  },
];

export default function IntroPageFour() {
  const pageStartOffset = useBreakpointValue({ base: 140, md: 0, lg: 0 }); //start all page animations 120vh later for mobile

  useEffect(() => {
    if (pageStartOffset) {
      headerPlxData[0].startOffset = addCSSOffset(headerPlxData[0].startOffset, pageStartOffset);
      headerPlxData[1].startOffset = addCSSOffset(headerPlxData[1].startOffset, pageStartOffset);

      // console.log(YearPlxData[0].startOffset, YearPlxData[1].startOffset);
      YearPlxData[0].startOffset = addCSSOffset(YearPlxData[0].startOffset, pageStartOffset);
      YearPlxData[1].startOffset = addCSSOffset(YearPlxData[1].startOffset, pageStartOffset);

      // console.log(YearPlxData[0].startOffset, YearPlxData[1].startOffset);
    }
  }, [pageStartOffset]);

  return (
    <Flex h='100vh' position='fixed' w='100%' justify='center' align='center' direction='column'>
      <Plx parallaxData={headerPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Text
            textStyle='titleNormal'
            textAlign='center'
            fontSize={['20px', '20px', '28px']}
            lineHeight={['30px', '30px', '38px']}
            maxW='770px'
            mx={['40px', 'unset', 'unset']}
            color='#FCFAF8'>
            Rural communities are connected and contribute to global commerce. There are myriad pathways for earning
            beyond agriculture.
          </Text>
        </Flex>
        <AnimeImage
          position='absolute'
          top={['-50%', '-155%', '-155%']}
          right={['-43%', '-23%', '-23%']}
          w={['330px', '564px', '564px']}
          h={['252px', '431px', '431px']}
          src={ortheogrid}
        />
      </Plx>
      <Plx parallaxData={YearPlxData} style={{ opacity: 0, width: '100%' }}>
        <Flex justify='center' align='center' h='100%' direction='column'>
          <Text
            mt={['35px', '35px', '58px']}
            textStyle='titleNormal'
            textAlign='center'
            fontSize={['20px', '20px', '28px']}
            lineHeight={['30px', '30px', '38px']}
            maxW='770px'
            mx={['40px', 'unset', 'unset']}
            color='#FCFAF8'>
            Connecting the last mile multiplies its latent potential.
          </Text>
        </Flex>
        <AnimeImage
          position='absolute'
          top={['50%', '150%', '150%']}
          left={['-30%', '-3%', '-3%']}
          w={['212px', '362px', '362px']}
          h={['233px', '398px', '398px']}
          direction='reverse'
          src={pyramid}
        />
        <AnimeImage
          position='absolute'
          display={['none', 'block', 'block']}
          top={['50%', '350%', '350%']}
          right='20%'
          w={['212px', '319px', '319px']}
          h={['233px', '297px', '297px']}
          transform='rotate(19.4deg)'
          src={cone}
        />
      </Plx>
    </Flex>
  );
}
