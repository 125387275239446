import React from 'react';
import { Image } from '@chakra-ui/react';
import styled from '@emotion/styled';

const RotateImage = styled(Image)`
  -webkit-animation: animeRotate 120s linear infinite;
  -moz-animation: animeRotate 120s linear infinite;
  animation: animeRotate 120s linear infinite;
  animation-direction: ${(props) => props.direction};

  @-webkit-keyframes animeRotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes animeRotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes animeRotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function AnimeImage(props) {
  const { src, direction = 'normal', ...css } = props;
  return <RotateImage direction={direction} {...css} src={src} />;
}
