import React from 'react';
import { Flex, Box, Text, Divider, Image, Button, Icon } from '@chakra-ui/react';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import VideoPlayer from '../Components/VideoPlayer';
import pyramid from '../images/icons/pyramid-dark.svg';
import staroutline from '../images/icons/star-outline.svg';
import pyramidgrid from '../images/icons/pyramidgrid.svg';

function Main() {
  return (
    <Flex direction='column' mt={['80px', '80px', '100px']}>
      <Flex
        h={['calc(100vh - 80px)', 'calc(100vh - 80px)', 'calc(100vh - 100px)']}
        bgColor='rgba(217,173,119,0.8)'
        justify={['space-between', 'space-between', 'space-between']}
        align='center'
        direction='column'>
        <Flex>
          <VideoPlayer
            width={['100vw', '100vw', '77vw']}
            type='video/mp4'
            height='auto'
            src='https://storage.googleapis.com/changeplus-video-storage/ChangePlusIntroVideoFINAL1080-20210929.mp4'
            poster='/images/changeplus-intro-video-cover.png'
          />
          {/* <video controls playsInline width='1128px' height='auto' poster='/images/changeplus-intro-video-cover.png'>
            <source
              src='https://storage.googleapis.com/changeplus-video-storage/ChangePlusIntroVideoFINAL1080-20210929.mp4'
              type='video/mp4'></source>
          </video> */}
        </Flex>
        <Flex direction='column' align='center'>
          <Text textStyle='sansBold' fontSize='14px' textTransform='uppercase'>
            Scroll Down
          </Text>
          <Divider
            borderColor='#000'
            borderLeftWidth='2px'
            orientation='vertical'
            h={['128px', '128px', '64px']}
            mt='8px'
          />
        </Flex>
      </Flex>
      <Flex h='auto' bgColor='rgba(217,173,119,0.8)' direction='column' justify='space-between'>
        <Flex
          direction='column'
          justify='space-between'
          align='center'
          mx={['20px', '20px', '180px']}
          mt={['56px', '56px', '69px']}>
          <Text
            display={['none', 'none', 'block']}
            textStyle='titleNormal'
            fontSize='40px'
            lineHeight='45px'
            textAlign='left'
            maxW='1026px'>
            Change+ is an invitation to imagine what connected and empowered last mile communities could look like in
            2030. We focus on the power of digital financial services to open up new pathways of opportunity for
            low-income women and rural communities. Let’s explore.
          </Text>
          <Text
            display={['block', 'block', 'none']}
            textStyle='titleNormal'
            fontSize='28px'
            lineHeight='36px'
            textAlign='left'
            maxW='1026px'>
            Change+ is an invitation to imagine what connected and empowered last mile communities could look like in
            2030.
          </Text>
          <Divider
            mt='32px'
            display={['none', 'none', 'inherit']}
            borderColor='#000'
            borderLeftWidth='2px'
            orientation='vertical'
            h='64px'
          />

          <Flex
            mt={['66px', '66px', '32px']}
            align='center'
            justify='center'
            maxW='1024px'
            position='relative'
            direction='column'>
            <Image
              mt='10px'
              position='absolute'
              maxW={['276px', '276px', '631px']}
              maxH={['140px', '140px', '204px']}
              transform={['scaleY(1.4)', 'scale(1.4)', 'scale(1.4)']}
              src='/images/ellipse-orange.svg'></Image>
            <Text
              mt={['10px', '10px', '10px']}
              textStyle='titleNormal'
              fontSize={['32px', '32px', '144px']}
              lineHeight={['40px', '40px', '144px']}
              color='#000'
              textTransform='uppercase'
              textAlign='center'
              zIndex='2'
              maxW={['276px', '276px', 'unset']}>
              Step into the future.
            </Text>
          </Flex>
        </Flex>
        <Flex direction='column' align='center' mt={['66px', '66px', '49px']}>
          <Text textStyle='sansBold' fontSize='14px' textTransform='uppercase'>
            Want to Know More?
          </Text>
          <Divider borderColor='#000' borderLeftWidth='2px' orientation='vertical' h='64px' mt='8px' />
        </Flex>
      </Flex>
      <Flex
        h={['auto', 'auto', 'auto']}
        minH={[null, null, 'calc(100vh - 100px)']}
        bgColor='rgba(220,227,197,0.8)'
        direction='column'
        align='center'
        justify='space-between'>
        <Text
          textStyle={['sansNormal', 'sansNormal', 'titleNormal']}
          textAlign={['center', 'center', 'left']}
          mt={['34px', '34px', '80px']}
          fontSize={['24px', '24px', '40px']}>
          Imagine a future where...
        </Text>
        <Flex align='center' direction={['column', 'column', 'row']} mt={['37px', '37px', 'unset']}>
          <Flex w={['unset', 'unset', '50%']} position='relative' justify='center' align='center'>
            <Image src='/images/star.svg' w={['200px', '200px', '460px']} h={['200px', '200px', '460px']}></Image>
            <Image
              position='absolute'
              w={['167px', '167px', 'unset']}
              h={['167px', '167px', 'unset']}
              src='/images/character/Sumaiya-04-Finja_LightGreen-web.png'></Image>
          </Flex>
          <Box w={['unset', 'unset', '50%']} ml={['30px', '30px', '60px']} mr={['30px', '30px', '120px']}>
            <Text
              textStyle='titleNormal'
              mt={['22px', '22px', '49px']}
              fontSize={['28px', '28px', '48px']}
              lineHeight={['36px', '36px', '52px']}>
              People coming online for the first time can gain digital confidence with every interaction.
            </Text>
            <Text
              textStyle='sansNormal'
              mt='32px'
              fontSize={['16px', '16px', '19px']}
              lineHeight={['24px', '24px', '28px']}>
              New internet users are welcomed online by inclusive products and services. Voice interactions reign. Users
              can try out new digital financial tools resting assured that a typo will not result in economic loss.
            </Text>
            <Text
              textStyle='sansNormal'
              mt='32px'
              fontSize={['16px', '16px', '19px']}
              lineHeight={['24px', '24px', '28px']}>
              Learn how these changes transform one life.
            </Text>
            <Flex justify='left'>
              <Button
                as={Link}
                to='/sumaiya'
                mt='36px'
                _hover={{
                  textDecoration: 'underline',
                  textDecorationColor: '#000',
                  textDecorationThickness: '4px',
                  textUnderlineOffset: '8px',
                }}
                variant='link'>
                <Text textStyle='sansBold' fontSize='18px' color='#000'>
                  Meet Sumaiya
                </Text>
                <Icon as={MdArrowForward} w='24px' h='24px' ml='10px' color='#000' />
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Flex direction='column' align='center' mt={['16px', '16px', 'unset']}>
          <Image w='20px' h='20px' src='/images/star-outline.svg'></Image>
          <Divider borderColor='#000' borderLeftWidth='2px' orientation='vertical' h='64px' mt='8px' />
        </Flex>
      </Flex>

      <Flex
        h={['auto', 'auto', 'auto']}
        minH={[null, null, 'calc(100vh - 100px)']}
        bgColor='rgba(225,228,222,0.8)'
        direction='column'
        align='center'
        justify='space-between'>
        <Flex
          align='center'
          justify='center'
          direction={['column-reverse', 'column-reverse', 'row']}
          mt={['32px', '32px', '0']}
          h='100%'>
          <Box w={['unset', 'unset', '50%']} ml={['30px', '30px', '120px']} mr={['30px', '30px', '120px']}>
            <Text
              textStyle='titleNormal'
              mt='36px'
              fontSize={['28px', '28px', '48px']}
              lineHeight={['36px', '36px', '52px']}>
              Connectivity helps rural businesses grow more robust and resilient.
            </Text>
            <Text
              textStyle='sansNormal'
              mt='32px'
              fontSize={['16px', '16px', '19px']}
              lineHeight={['24px', '24px', '28px']}>
              Rural communities take advantage of new economic activities made possible by widespread internet
              connectivity. Smallholder farmers can command premium pricing for crops, craftspeople can sell directly to
              global customers through online stores, and new microwork and remote work possibilities draw youth to
              rural communities, instead of away from them.
            </Text>
            <Text
              textStyle='sansNormal'
              mt='32px'
              fontSize={['16px', '16px', '19px']}
              lineHeight={['24px', '24px', '28px']}>
              Learn how these changes transform one life.
            </Text>
            <Flex justify='left'>
              <Button
                as={Link}
                to='/reza'
                mt='32px'
                _hover={{
                  textDecoration: 'underline',
                  textDecorationColor: '#000',
                  textDecorationThickness: '4px',
                  textUnderlineOffset: '8px',
                }}
                variant='link'>
                <Text textStyle='sansBold' color='#000'>
                  Meet Reza
                </Text>
                <Icon as={MdArrowForward} w='24px' h='24px' ml='10px' color='#000' />
              </Button>
            </Flex>
          </Box>
          <Flex w={['unset', 'unset', '50%']} position='relative' justify='center' align='center'>
            <Image src='/images/wavey.svg' w={['200px', '200px', '460px']} h={['200px', '200px', '460px']}></Image>
            <Image
              position='absolute'
              w={['167px', '167px', 'unset']}
              h={['167px', '167px', 'unset']}
              src='/images/character/Reza-04-Pesakit-Store_LightGrey-web.png'></Image>
          </Flex>
        </Flex>
        <Flex direction='column' align='center' mt={['57px', '57px', 'unset']}>
          <Image w='20px' h='20px' src='/images/star-outline.svg'></Image>
          <Divider borderColor='#000' borderLeftWidth='2px' orientation='vertical' h='64px' mt='8px' />
        </Flex>
      </Flex>

      <Flex
        h={['auto', 'auto', 'auto']}
        minH={[null, null, 'calc(100vh - 100px)']}
        bgColor='rgba(242,221,222,0.8)'
        direction='column'
        align='center'
        justify='space-between'>
        <Flex align='center' direction={['column', 'column', 'row']} mt={['52px', '52px', '0']} h='100%'>
          <Flex w={['unset', 'unset', '50%']} position='relative' justify='center' align='center'>
            <Image src='/images/union.svg' w={['200px', '200px', '460px']} h={['200px', '200px', '460px']}></Image>
            <Image
              position='absolute'
              w={['167px', '167px', 'unset']}
              h={['167px', '167px', 'unset']}
              src='/images/character/Consolata-04-Projects_Pink-web.png'></Image>
          </Flex>
          <Box w={['unset', 'unset', '50%']} ml={['30px', '30px', '60px']} mr={['30px', '30px', '120px']}>
            <Text
              textStyle='titleNormal'
              mt='36px'
              fontSize={['28px', '28px', '48px']}
              lineHeight={['36px', '36px', '52px']}>
              Women and last-mile communities have better tools at their disposal.
            </Text>
            <Text
              textStyle='sansNormal'
              mt='32px'
              fontSize={['16px', '16px', '19px']}
              lineHeight={['24px', '24px', '28px']}>
              Instead of innovation trickling from urban centers out, a wave of new products emerges when companies
              prioritize designing for the last mile. New kinds of partnerships emerge as private and public sector
              actors come together to design digital financial services that are well-suited to the needs of
              historically vulnerable populations.
            </Text>
            <Text
              textStyle='sansNormal'
              mt='32px'
              fontSize={['16px', '16px', '19px']}
              lineHeight={['24px', '24px', '28px']}>
              Learn how these changes transform one life.
            </Text>
            <Flex justify='left'>
              <Button
                as={Link}
                to='/consolata'
                mt='32px'
                _hover={{
                  textDecoration: 'underline',
                  textDecorationColor: '#000',
                  textDecorationThickness: '4px',
                  textUnderlineOffset: '8px',
                }}
                variant='link'>
                <Text textStyle='sansBold' color='#000'>
                  Meet Consolata
                </Text>
                <Icon as={MdArrowForward} w='24px' h='24px' ml='10px' color='#000' />
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Flex direction='column' align='center' mt={['57px', '57px', 'unset']}>
          <Image w='20px' h='20px' src='/images/star-outline.svg'></Image>
          <Divider borderColor='#000' borderLeftWidth='2px' orientation='vertical' h='64px' mt='8px' />
        </Flex>
      </Flex>

      <Flex
        position='relative'
        h='auto'
        minH={['478px', '478px', 'calc(68vh - 100px)']}
        direction='column'
        overflow='hidden'
        bgGradient='linear(to-b, #698C94, #B9CC99)'
        justify={['space-evenly', 'space-evenly', 'center']}>
        <Image position='absolute' top={['40px', '40px', '15%']} right='18%' w='59px' h='59px' src={staroutline} />
        <Image
          position='absolute'
          top={['85px', '85px', '20%']}
          right={['-40px', '-40px', '10px']}
          w={['95px', '95px', '127px']}
          h={['105px', '105px', '140px']}
          src={pyramid}
        />
        <Image position='absolute' bottom='-25px' left='10%' w='127px' h='auto' src={pyramidgrid} />

        <Flex direction='column' align='center' justify='center' position='relative'>
          <Image
            position='absolute'
            maxW={['276px', '276px', '952px']}
            maxH={['140px', '140px', '308px']}
            transform={['scaleY(1.4)', 'scale(1.4)', 'scale(1.4)']}
            src='/images/ellipse-orange.svg'></Image>
          <Text
            mt={['10px', '10px', 'unset']}
            textStyle='titleNormal'
            textTransform='uppercase'
            fontSize={['32px', '32px', '144px']}
            lineHeight={['40px', '40px', '144px']}
            color='#000'
            textAlign='center'
            zIndex='2'
            maxW={['246px', '246px', '1080px']}>
            Explore the future
          </Text>
        </Flex>
        <Flex justify='center' mt={['unset', 'unset', '30px']}>
          <Button
            as={Link}
            to='/future-world'
            size='lg'
            _hover={{ bg: '#000', color: '#fff' }}
            _active={{ bg: '#000', color: '#fff' }}
            variant='outline'
            color='#000'
            border='solid 2px'
            borderColor='#000'
            borderRadius='50px'
            w='233px'
            h='58px'>
            <Text textStyle='sansBold' pt='3px'>
              Let's Go
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box
        bgImage="url('/images/ecosystem.png')"
        bgPosition='center'
        position='fixed'
        zIndex='-1'
        bgRepeat='no-repeat'
        bgSize={['250%', '250%', 'contain']}
        h='100vh'
        w='100vw'></Box>
      <Footer />
    </Flex>
  );
}

export default Main;
